body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/montserrat-v25-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../public/assets/fonts/montserrat-v25-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/montserrat-v25-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../public/assets/fonts/montserrat-v25-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/montserrat-v25-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../public/assets/fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/montserrat-v25-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/montserrat-v25-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/montserrat-v25-latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('../public/assets/fonts/montserrat-v25-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/montserrat-v25-latin-500.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../public/assets/fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/montserrat-v25-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/montserrat-v25-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/montserrat-v25-latin-600.woff') format('woff'),
    /* Modern Browsers */
      url('../public/assets/fonts/montserrat-v25-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/montserrat-v25-latin-600.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/inter-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/inter-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/inter-v12-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/inter-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('../public/assets/fonts/inter-v12-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/inter-v12-latin-regular.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../public/assets/fonts/inter-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/inter-v12-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/inter-v12-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/inter-v12-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../public/assets/fonts/inter-v12-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/inter-v12-latin-500.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../public/assets/fonts/inter-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/inter-v12-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/inter-v12-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/inter-v12-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../public/assets/fonts/inter-v12-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/inter-v12-latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/poppins-v20-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/poppins-v20-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/poppins-v20-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../public/assets/fonts/poppins-v20-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/poppins-v20-latin-regular.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../public/assets/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/poppins-v20-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/poppins-v20-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/poppins-v20-latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('../public/assets/fonts/poppins-v20-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/poppins-v20-latin-500.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../public/assets/fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../public/assets/fonts/poppins-v20-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/assets/fonts/poppins-v20-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/assets/fonts/poppins-v20-latin-600.woff') format('woff'),
    /* Modern Browsers */
      url('../public/assets/fonts/poppins-v20-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../public/assets/fonts/poppins-v20-latin-600.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
